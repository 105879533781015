<template>
  <div class="flex flex-col p-2">
    <div class="mt-2 mb-8 flex flex-row flex-wrap items-center">
      <div
        v-for="(curPeriod, periodIndex) in periods"
        :key="periodIndex"
        class="p-1"
      >
        <a-tag>
          <a-radio-group v-model="period">
            <a-radio
              :value="curPeriod"
            >
              <span class="font-semibold">
                {{ `${curPeriod} ${$tc('period', curPeriod)}` }}
              </span>
            </a-radio>
          </a-radio-group>

        </a-tag>
      </div>

    </div>

    <div class="flex flex-col-reverse md:flex-row flex-wrap items-center">
      <div
        v-for="(tariff, tariffsIndex) in tariffsMap[period]"
        :key="tariff.name + tariffsIndex"
        class="p-2"
      >
        <div
          class="language-card"
          :class="{ 'language-card_active': tariff === selectedTariff  }"
          :style="{boxShadow: `0 2px 10px 0 ${config.tariffs[tariff.name].color}`}"
          :title="tariff.name"
          @click="selectedTariff = tariff"
        >
          <selected-icon
            class="selected-icon"
            :active="tariff === selectedTariff"
          />
          <div class="relative">
            <div class="img-wrapper"/>
            <img
              :src="config.tariffs[tariff.name].img"
              :alt="tariff.name"
            >
            <div class="price flex flex-col">
              <p class="font-semibold capitalize">
                {{ tariff.name }}
              </p>

              <div class="flex flex-row items-start">
                <div
                  v-if="tariff.price < tariff.basePrice"
                  class="base-price text-sm mr-2"
                >
                  {{ tariff.basePrice }}{{ tariff.currency }}
                </div>
                <div
                  class="text-md"
                  :class="{'font-semibold': tariff.price < tariff.basePrice}"
                >
                  {{ tariff.price }}{{ tariff.currency }}
                </div>
              </div>
            </div>
          </div>
          <!--      <vs-chip-->
          <!--          class="tariff-title"-->
          <!--          :color="config.tariffs[tariff.name].color"-->
          <!--      >-->
          <!--        <p>-->
          <!--          {{ tariff.name }}-->
          <!--        </p>-->
          <!--      </vs-chip>-->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import SelectedIcon from '@/components/chat-adding/SelectedIcon'
import project from '@/config'

// basePrice:3
// currency:"USD"
// descriptions:Array[0]
// discount:0
// key:1
// name:"starter"
// packageBasePrice:3
// packageCount:1
// packagePrice:3
// price:3
// shops:Object (empty)
// timeInterval:"MONTHS"
// type:"Single"

export default {
  i18n: {
    messages: {
      ru: {
        period: "месяц | месяц | месяца | месяцев",
        period_title: 'Период'
      },
      en: {
        period: "month | months",
        period_title: 'Period'
      }
    }
  },

  name: "SelectTariff",

  props: [ 'value', 'tariffs' ],

  components: {
    SelectedIcon,
  },

  data() {
    return {
      config: project.config,

      tariffsMap: {},

      periods: null,
      period: null
    }
  },

  computed: {
    selectedTariff: {
      get() {
        return this.value
      },
      set(tariff) {
        this.$emit('input', tariff)
      }
    }
  },

  created() {
    this.tariffs.forEach((tariff) => {
      if (this.tariffsMap[tariff.packageCount]) {
        this.tariffsMap[tariff.packageCount].push(tariff)
      } else {
        this.tariffsMap[tariff.packageCount] = [ tariff ]
      }
    })

    this.periods = Object.keys(this.tariffsMap)

    this.period = Math.max(...this.periods)
  }
}
</script>

<style scoped lang="scss">
@mixin language-card-hover-state {
  transform: translateY(-5px);
  //box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}

@mixin language-card-active-state {
  transform: translateY(-5px) scale(.8);
  //box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

@mixin language-card-disable-translate {
  transform: translateY(-5px) !important;
  //box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3) !important;
  cursor: default !important;
}

.language-card {
  position: relative;
  padding: 10px;
  border-radius: 4px;
  //box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  cursor: pointer;
  user-select: none;

  * {
    user-select: none;
  }

  &:hover {
    @include language-card-hover-state
  }

  &:active {
    @include language-card-active-state
  }

  &_active {
    &:hover {
      @include language-card-disable-translate
    }

    &:active {
      @include language-card-disable-translate
    }
  }

  .selected-icon {
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .img {
    border-radius: 4px;
    width: 50px;
    max-width: 100%;
    height: 30px;
    max-height: 100%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  img {
    border-radius: 4px;
    width: 150px;
    max-width: 100%;
  }
}

.tariff-title {
  //position: absolute;
  //left: 50%;
  //bottom: -10px;
  //transform: translateX(-50%);

  p {
    text-align: center;
    font-weight: 500;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.img-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.base-price {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    background: rgba(var(--a-danger), .8);
  }
}
</style>
